import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { useGetLegalUnits } from '@/hooks/legal-unit/LegalUnit.hook';
import { RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { LegalUnit } from '@/domain/legal-unit/LegalUnit.model';
import { StateHandler } from '@/components/state-handler/StateHandler';

export const LegalUnitsSettingsPage: FC = () => {
    const { t } = useTranslation();

    const { data: legalUnits = [], isError, isLoading, error } = useGetLegalUnits();

    const config: ConfigType<LegalUnit> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('legal_units_settings_page.legal_unit')}`,
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'legalName',
                },
                {
                    field: 'displayName',
                },
            ] satisfies RogerColDef<LegalUnit>[],
        },
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <CompanySettingsLayout options={config} data={legalUnits} isSearchAvailable={false} />
        </StateHandler>
    );
};
