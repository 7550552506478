import { flatTreeNodeSchema } from '@/components/autocomplete-wrapper/TreeAutoComplete/useTreeAutoComplete.schema';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { ContractType, EmploymentCreateReason } from '@/domain/employment/Employment.model';
import { Job } from '@/domain/job/Job.model';
import { LocationWithoutCircularReference } from '@/domain/location/Location.model';
import { getCostCenterAssignmentListObjectSchema } from '@/page/cost-center/CostCentersAssignment.schema';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import i18next from 'i18next';
import * as yup from 'yup';
import { isDateInAllowedRanges } from '@/domain/employment/Employment.service';

export type EmploymentSchemaConfig = {
    startDate?: {
        allowedStartDateRanges?: [LocalDate, LocalDate | undefined][];
        rangeErrorMessage?: string;
    };
};
export const getEmploymentSchema = (config: EmploymentSchemaConfig = {}) => {
    return yup.object().shape({
        startDate: yup
            .string<LocalDate>()
            .required()
            .test(getLocalDateTestConfig())
            .test({
                name: 'start-date-outside-contract',
                message: config.startDate?.rangeErrorMessage ?? i18next.t('employee.employment.no_start_date_outside_contract'),
                test: startDate => {
                    const { allowedStartDateRanges } = config.startDate ?? {};
                    return !startDate || !allowedStartDateRanges || isDateInAllowedRanges(startDate, allowedStartDateRanges);
                },
            }),
        employmentUpdateReason: yup.string().oneOf(Object.values(EmploymentCreateReason)).required(),
        contractType: yup.string().required().oneOf(Object.values(ContractType), i18next.t('general.validations.required')),
        probationEndDate: yup.string<LocalDate>().default(getNull()).nullable().test(getLocalDateTestConfig()),
        items: yup.array().of(getEmploymentItemSchema()).required(),
    });
};

export const getEmploymentItemSchema = () => {
    return yup.object().shape({
        id: yup.number().nullable(),
        department: flatTreeNodeSchema.required(),
        managers: yup
            .array()
            .of(yup.object() as yup.ObjectSchema<EmployeeAvatar>)
            .default([])
            .required(),
        employmentCostCenters: getCostCenterAssignmentListObjectSchema(),
        // Fix the type of location, department, job,
        location: yup.object().required() as unknown as yup.Schema<LocationWithoutCircularReference>,
        job: yup.object().required() as unknown as yup.Schema<Job>,
        // Job Family is optional
        jobFamily: yup.object().nullable().default(getNull()).shape({
            id: yup.number().required(),
        }),
    });
};

export const getEmploymentDialogSchema = (config: EmploymentSchemaConfig = {}) => getEmploymentSchema(config).omit(['contractType', 'probationEndDate']);

export type EmploymentDialogFormValues = yup.InferType<ReturnType<typeof getEmploymentDialogSchema>>;
