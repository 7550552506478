import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeRole } from '@/domain/employee-role/EmployeeRole.model';
import { EmployeeReviewFeedback, EmployeeReviewFeedbackMutation } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { ContributorType } from '@/domain/review/Review.model';
import { Objective } from '@/domain/objective/Objective.model';
import { Employee } from '@/domain/employee/Employee.model';
import {
    employeeReviewFeedbackFormSchema,
    EmployeeReviewFeedbackFormType,
    EmployeeReviewFeedbackItemForm,
    EmployeeReviewFeedbackQuestionFormType,
    EmployeeReviewFeedbackSkillFormType,
    EmployeeReviewFeedbackSkillQuestionFormType,
    EmployeeReviewFeedbackSkillsFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { FC, useEffect, useRef, useState } from 'react';
import { Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getEmployeeReviewContributorType } from '@/domain/employee-review/EmployeeReview.service';
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { submitEmployeeReviewFeedback, updateEmployeeReviewFeedbackItem } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { handleError } from '@/utils/api.util';
import { EmployeeReviewFeedbackSection } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSection';
import { EmployeeReviewFeedbackQuestion } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackQuestion';
import { EmployeeReviewFeedbackSkills } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkills';
import { EmployeeReviewFeedbackObjectives } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackObjectives';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { nonNullable } from '@/utils/object.util';
import { linkRef } from '@/utils/dom.util';
import { EmployeeReviewFeedbackFormHeader } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormHeader';
import { employeeReviewFeedbackFormUtil } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackForm.util';
import { ContentContainer } from '@/page/layout/ContentContainer';

type EmployeeReviewFeedbackFormProps = {
    employeeReview: EmployeeReview;
    employeeRoles: EmployeeRole[];
    employeeReviewFeedback: EmployeeReviewFeedback | undefined;
    employeeId: number;
    isPreview: boolean;
    previewRole: ContributorType | undefined;
    objectives: Objective[];
    refetchObjectives: () => void;
    isFormDisabled: boolean;
    currentEmployee: Employee;
};

export const EmployeeReviewFeedbackForm: FC<EmployeeReviewFeedbackFormProps> = ({
    employeeReview,
    employeeRoles,
    previewRole,
    isPreview,
    employeeId,
    employeeReviewFeedback,
    objectives,
    refetchObjectives,
    isFormDisabled,
    currentEmployee,
}) => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const itemsRefs = useRef<HTMLDivElement[]>([]);
    const { t } = useTranslation();
    const [confirmSubmitFeedbackDialogOpen, setConfirmSubmitFeedbackDialogOpen] = useState(false);
    const navigate = useNavigate();

    const getConfirmSubmitFeedbackDialogSubtitle = () => {
        if (!employeeReview || !currentEmployee) {
            return;
        }
        const contributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);
        return t('reviews.write_feedback.confirm_submit_feedback_dialog_subtitle', {
            context: contributorType,
        });
    };

    const {
        control,
        formState: { errors },
        watch,
        handleSubmit,
    } = useForm<EmployeeReviewFeedbackFormType>({
        resolver: yupResolver(employeeReviewFeedbackFormSchema),
        defaultValues: employeeReviewFeedbackFormUtil.getDefaultValues(
            employeeReviewFeedback?.feedbacks,
            employeeRoles,
            previewRole,
            employeeReview,
            isPreview,
        ),
    });

    useEffect(() => {
        // we need to scroll to the first error
        if (!!Object.keys(errors)?.length && !!itemsRefs?.current?.length) {
            // we use the first error index to scroll to the first error
            const firstErrorIndex = errors?.employeeReviewFeedbackItems?.findIndex?.(item => !!item);
            if (!!firstErrorIndex || firstErrorIndex === 0) {
                itemsRefs.current[firstErrorIndex]?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [t, errors]);

    const { fields } = useFieldArray({
        control,
        name: 'employeeReviewFeedbackItems',
    });

    const onSaveItem = async (feedbackFormItem: EmployeeReviewFeedbackItemForm, employeeReviewFeedbackSkillForm?: EmployeeReviewFeedbackSkillFormType) => {
        // we don't want to save the feedback if we are in preview mode
        if (isPreview) {
            return;
        }
        let employeeReviewFeedbackMutation: EmployeeReviewFeedbackMutation;

        switch (feedbackFormItem.type) {
            case 'SECTION':
                return;
            case 'SKILL':
                employeeReviewFeedbackMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: feedbackFormItem.skillId,
                    objectiveId: undefined,
                };
                break;
            case 'QUESTION':
                employeeReviewFeedbackMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: undefined,
                    objectiveId: undefined,
                };
                break;
            case 'SKILLS':
                employeeReviewFeedbackMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: employeeReviewFeedbackSkillForm?.score,
                    comment: employeeReviewFeedbackSkillForm?.comment,
                    skillId: employeeReviewFeedbackSkillForm?.skill.id,
                    objectiveId: undefined,
                };
                break;
            default:
                return;
        }

        try {
            await updateEmployeeReviewFeedbackItem(employeeReview.id, employeeReviewFeedbackMutation);
        } catch (error) {
            handleError(error);
        }
    };

    const redirectAfterSubmit = () => {
        if (!currentEmployee || !employeeReview) {
            return;
        }

        const employeeReviewContributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);

        if (employeeReviewContributorType === 'EMPLOYEE') {
            navigate('/profile/reviews');
        } else {
            navigate('/reviews/tasks');
        }
    };

    const onSave = async (feedbackFormData: EmployeeReviewFeedbackFormType) => {
        const employeeReviewFeedbacksMutation: EmployeeReviewFeedbackMutation[] = feedbackFormData.employeeReviewFeedbackItems
            .filter(
                (item): item is EmployeeReviewFeedbackSkillQuestionFormType | EmployeeReviewFeedbackQuestionFormType | EmployeeReviewFeedbackSkillsFormType =>
                    item.type === 'SKILL' || item.type === 'QUESTION' || item.type === 'SKILLS',
            )
            .map(item => {
                switch (item.type) {
                    case 'SKILL':
                        return {
                            reviewItemId: item.id,
                            score: item.score,
                            comment: item.comment,
                            skillId: item.skillId,
                            objectiveId: undefined,
                        };
                    case 'QUESTION':
                        return {
                            reviewItemId: item.id,
                            score: item.score,
                            comment: item.comment,
                            skillId: undefined,
                            objectiveId: undefined,
                        };
                    case 'SKILLS':
                        return item.skills
                            .filter(skill => skill.score)
                            .map(skill => {
                                return {
                                    reviewItemId: item.id,
                                    score: skill.score,
                                    comment: skill.comment,
                                    skillId: skill.skill.id,
                                    objectiveId: undefined,
                                };
                            });
                }
            })
            .filter(nonNullable)
            .flat();

        if (!employeeReviewFeedbacksMutation || !employeeReview) {
            return;
        }

        try {
            await submitEmployeeReviewFeedback(employeeReview.id, employeeReviewFeedbacksMutation);
            redirectAfterSubmit();
        } catch (error) {
            handleError(error);
        }
    };

    const openConfirmSubmitFeedbackDialog = () => {
        setConfirmSubmitFeedbackDialogOpen(true);
    };

    const updateEmployeeReviewFeedbackSkill =
        (value: EmployeeReviewFeedbackSkillsFormType, onChange: (v: EmployeeReviewFeedbackSkillsFormType) => void) =>
        (employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSkillFormType) => {
            const skillsItem = value;
            const updatedSkills = skillsItem.skills.map(skill => {
                if (skill.skill.id === employeeReviewFeedbackSkillForm.skill.id) {
                    return employeeReviewFeedbackSkillForm;
                }
                return skill;
            });
            onChange({ ...skillsItem, skills: updatedSkills });
            onSaveItem(skillsItem, employeeReviewFeedbackSkillForm).catch(console.error);
        };

    return (
        <>
            <ContentContainer>
                <Stack
                    direction='column'
                    sx={{ pb: isMobile ? 0 : 5 }}
                    gap={2}
                    id={'employee-review-feedback-form'}
                    component={'form'}
                    onSubmit={handleSubmit(openConfirmSubmitFeedbackDialog, console.error)}
                >
                    <Stack width={isMobile ? '100%' : '800px'} direction='column' alignSelf={'center'} gap={2}>
                        <EmployeeReviewFeedbackFormHeader
                            employeeReviewFeedback={employeeReviewFeedback}
                            employeeReviewFeedbackItems={watch('employeeReviewFeedbackItems')}
                            employeeReview={employeeReview}
                            isPreview={isPreview}
                            objectives={objectives}
                            currentEmployee={currentEmployee}
                        />

                        <Stack gap={2} direction='column'>
                            {fields.map((item, index) => {
                                switch (item?.type) {
                                    case 'SECTION':
                                        return <EmployeeReviewFeedbackSection sectionItem={item} key={item.id} />;
                                    case 'SKILLS':
                                        if (isPreview) {
                                            return (
                                                <Stack component={Paper} key={item.id} p={2}>
                                                    <Typography variant={'h2'}>{t('reviews.write_feedback.skills_preview')}</Typography>
                                                </Stack>
                                            );
                                        }

                                        return (
                                            <Controller
                                                name={`employeeReviewFeedbackItems.${index}`}
                                                control={control}
                                                key={item.id}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <EmployeeReviewFeedbackSkills
                                                        innerRef={linkRef(index, itemsRefs)}
                                                        // Todo: fix the as EmployeeReviewFeedbackSkillsFormType
                                                        skillsItem={value as EmployeeReviewFeedbackSkillsFormType}
                                                        handleSkillChanged={updateEmployeeReviewFeedbackSkill(
                                                            value as EmployeeReviewFeedbackSkillsFormType,
                                                            onChange,
                                                        )}
                                                        error={error}
                                                        disabled={isFormDisabled}
                                                    />
                                                )}
                                            />
                                        );
                                    case 'OBJECTIVES':
                                        return (
                                            <EmployeeReviewFeedbackObjectives
                                                objectives={objectives}
                                                employeeId={employeeId}
                                                disabled={isFormDisabled}
                                                key={item.id}
                                                refetchObjectives={refetchObjectives}
                                            />
                                        );
                                    case 'QUESTION':
                                    case 'SKILL':
                                    default:
                                        return (
                                            <Controller
                                                name={`employeeReviewFeedbackItems.${index}`}
                                                control={control}
                                                key={item.id}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <EmployeeReviewFeedbackQuestion
                                                        p={2}
                                                        innerRef={linkRef(index, itemsRefs)}
                                                        questionItem={value as EmployeeReviewFeedbackQuestionFormType}
                                                        disabled={isFormDisabled}
                                                        // have to cast the error because the type of the error in the output of fieldState error is not the right one
                                                        error={
                                                            error as {
                                                                comment?: FieldError;
                                                                score?: FieldError;
                                                            }
                                                        }
                                                        key={item.id}
                                                        onUpdate={value => {
                                                            onChange(value);
                                                            onSaveItem(value).catch(console.error);
                                                        }}
                                                        isSummary={false}
                                                    />
                                                )}
                                            />
                                        );
                                }
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </ContentContainer>

            {confirmSubmitFeedbackDialogOpen && (
                <DialogContainer
                    title={t('reviews.write_feedback.confirm_submit_feedback_dialog_title')}
                    saveButtonText={t('reviews.write_feedback.confirm_submit_feedback_dialog_confirm')}
                    open={true}
                    onClose={() => {
                        setConfirmSubmitFeedbackDialogOpen(false);
                    }}
                    onSave={handleSubmit(onSave, console.error)}
                >
                    <Typography variant='body2'>{getConfirmSubmitFeedbackDialogSubtitle()}</Typography>
                </DialogContainer>
            )}
        </>
    );
};
