import { Box, Chip, Stack, Typography } from '@mui/material';
import { TranslatableLabelTypography } from '@/components/translatable-label-typography/TranslatableLabelTypography';
import { FC } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { DragDropHorizontalIcon } from 'hugeicons-react';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import i18next from 'i18next';
import { MoreVerticalIcon } from '@/assets/icons/Icons';

type SectionFieldDefinitionDraggableProps = {
    sectionField: SectionFieldDefinition;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    onFieldUpdate: (field: SectionFieldDefinition) => void;
    onFieldDelete: (field: SectionFieldDefinition) => void;
    onFieldArchive?: (field: SectionFieldDefinition) => void;
    onFieldUnarchive?: (field: SectionFieldDefinition) => void;
};

export const SectionFieldDefinitionDraggable: FC<SectionFieldDefinitionDraggableProps> = ({
    sectionField,
    provided,
    snapshot,
    onFieldDelete = () => {},
    onFieldUpdate = () => {},
    onFieldArchive = () => {},
    onFieldUnarchive = () => {},
}) => {
    const { t } = useTranslation();

    const isCustomField = sectionField.fieldType === 'EMPLOYEE_CUSTOM_FIELD';
    // custom fields or non-mandatory default fields can be archived
    const canArchiveField = !sectionField.archived;
    const canUnarchiveField = sectionField.archived;

    const fieldMenuItems: BasicMenuItem[] = [
        {
            title: i18next.t('general.edit'),
            onClick: () => onFieldUpdate(sectionField),
            hide: sectionField.archived,
        },
        {
            title: i18next.t('general.delete'),
            onClick: () => onFieldDelete(sectionField),
            hide: !isCustomField || !sectionField.archived || sectionField.unmodifiable,
        },
        {
            title: i18next.t('general.archive'),
            onClick: () => onFieldArchive(sectionField),
            confirmationRequired: true,
            hide: !canArchiveField || sectionField.unmodifiable,
        },
        {
            title: i18next.t('general.unarchive'),
            onClick: () => onFieldUnarchive(sectionField),
            hide: !canUnarchiveField || sectionField.unmodifiable,
        },
    ];

    return (
        <Stack
            key={sectionField.id}
            direction='row'
            gap={2}
            alignItems='center'
            justifyContent='space-between'
            {...(snapshot.isDragging
                ? {
                      bgcolor: ({ palette }) => palette.common.white,
                      borderRadius: 1,
                      boxShadow: 1,
                  }
                : {})}
        >
            <Box display='contents' {...provided.dragHandleProps} alignSelf='center'>
                <DragDropHorizontalIcon />
            </Box>
            <Box flex='1'>
                <Stack direction='row' gap={1} alignItems='center'>
                    <TranslatableLabelTypography variant='body2bold' noWrap label={sectionField?.name} />
                    {sectionField.mandatory && <Chip label={t('employee_fields_page.table_columns.mandatory')} size='small' />}
                    {sectionField.archived && <Chip color={'warning'} label={t('general.archived')} />}
                </Stack>

                <Typography variant='body2' noWrap>
                    {t(`employee_fields_page.${sectionField.valueType}`)}
                </Typography>
            </Box>

            <BasicMenu items={fieldMenuItems} endIcon={<MoreVerticalIcon />} />
        </Stack>
    );
};
