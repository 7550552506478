import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Label } from '@/domain/label/Label.model';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { isCustomSectionType, isSectionType } from '@/domain/section-setting/Section.service';
import { getLabelPropertyName, getRealmLanguage } from '@/utils/language.util';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    MenuItem,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import { Cancel01Icon } from 'hugeicons-react';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SectionDefinitionFormValues = Pick<SectionDefinition, 'name' | 'type' | 'includeInPayroll' | 'tab'>;

type Props = {
    onSave: (section: SectionDefinitionFormValues, previous?: SectionDefinition) => void;
    onClose: () => void;
    /**
     * @property section if undefined, it means we are creating a new section
     */
    defaultSection?: SectionDefinition;
};

export const SectionDefinitionDialog: FC<Props> = ({ onSave, onClose, defaultSection }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const defaultLanguage = getRealmLanguage();
    const [translationLanguage, setTranslationLanguage] = useState(defaultLanguage);
    const defaultLabel = createDefaultLabel();

    const isCustomSection = !defaultSection || isCustomSectionType(defaultSection.type);

    // if we are editing a section, we fill the form with the section data
    // otherwise we set default values to keep the form controlled
    const [section, setSection] = useState<SectionDefinitionFormValues>(
        defaultSection ?? {
            name: defaultLabel,
            type: SectionType.CUSTOM_SINGLE_ROW,
            includeInPayroll: false,
            tab: 'PROFILE',
        },
    );

    const handleSave = async () => {
        onSave(section, defaultSection);
    };

    const handleSectionNameChange = (name: Label) => {
        setSection({
            ...section,
            name,
        });
    };

    const handleSectionTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const type = event.target.value;
        if (isSectionType(type)) {
            setSection({
                ...section,
                type,
            });
        }
    };

    // A form with custom fields is valid if the default language of the realm is filled
    const isFormValid = () => {
        return !!section?.name?.[getLabelPropertyName(defaultLanguage)];
    };

    return (
        <Dialog open={true} onClose={() => onClose()} fullScreen={fullScreen}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h1'>
                        {defaultSection ? t('employee_fields_page.add_section_dialog.edit_section') : t('employee_fields_page.add_section_dialog.new_section')}
                    </Typography>
                    <Stack direction='row' alignItems='center'>
                        <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                        <IconButton aria-label='close' onClick={() => onClose()}>
                            <Cancel01Icon />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    {/*todo: implement the error helper text message*/}
                    <TranslatableLabelInput
                        label={t('employee_fields_page.add_section_dialog.name')}
                        value={section.name}
                        onChange={handleSectionNameChange}
                        fullWidth
                        translationLanguage={translationLanguage}
                    />
                    {isCustomSection && (
                        <>
                            <FormControlLabel
                                label={t('employee_fields_page.add_section_dialog.type')}
                                disabled={!!defaultSection} // we can't change the type of a section that already exists
                                control={
                                    <TextField
                                        select
                                        fullWidth
                                        onChange={handleSectionTypeChange}
                                        value={section?.type}
                                        defaultValue={SectionType.CUSTOM_SINGLE_ROW}
                                    >
                                        <MenuItem value={SectionType.CUSTOM_SINGLE_ROW}>{t(`employee_fields_page.${SectionType.CUSTOM_SINGLE_ROW}`)}</MenuItem>
                                        <MenuItem value={SectionType.CUSTOM_MULTI_ROW}>{t(`employee_fields_page.${SectionType.CUSTOM_MULTI_ROW}`)}</MenuItem>
                                    </TextField>
                                }
                            />
                            <FormControlLabel
                                label={t('employee_fields_page.add_section_dialog.tab')}
                                disabled={!isCustomSection}
                                control={
                                    <TextField
                                        select
                                        fullWidth
                                        onChange={e =>
                                            setSection({
                                                ...section,
                                                tab: e.target.value as SectionDefinition['tab'],
                                            })
                                        }
                                        value={section.tab}
                                        defaultValue={'PROFILE'}
                                    >
                                        <MenuItem value={'PROFILE' satisfies SectionDefinition['tab']}>
                                            {t('employee_fields_page.add_section_dialog.tab_profile')}
                                        </MenuItem>
                                        <MenuItem value={'JOB' satisfies SectionDefinition['tab']}>
                                            {t(t('employee_fields_page.add_section_dialog.tab_job'))}
                                        </MenuItem>
                                    </TextField>
                                }
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={!isFormValid()} variant='contained' onClick={handleSave}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
