import { FC, HTMLAttributes } from 'react';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { FlatTreeNode } from '@/components/autocomplete-wrapper/TreeAutoComplete/useTreeAutoComplete';
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react';

type TreeOptionProps = HTMLAttributes<HTMLLIElement> & {
    node: FlatTreeNode;
    parentId?: number;
    onNavigateToChildren: (node: FlatTreeNode) => void;
    onGoBack: (id: number) => void;
    canSelectNode?: boolean;
};

/**
 * TreeOption is a component that displays a TreeNode as an option in the TreeAutoComplete component.
 * It displays the name of the node and a button to navigate to the children of the node.
 * If the node is the parent node, it displays a back button to navigate to the parent node.
 * @param props
 */
export const TreeOption: FC<TreeOptionProps> = props => {
    const { node, parentId, onNavigateToChildren, onGoBack, canSelectNode = true, onClick, ...rest } = props;
    const isParent = node.id === parentId;
    const hasChildren = !isParent && node.hasChildren;

    return (
        <ListItemButton
            component={'li'}
            disabled={node.disabled}
            onClick={e => {
                if (isParent) {
                    onGoBack(parentId);
                    return;
                }
                // force navigation to children if we can't select a node and the current node has children
                if (node.hasChildren && !canSelectNode) {
                    onNavigateToChildren(node);
                    return;
                }
                onClick?.(e);
            }}
            divider={isParent}
            disableRipple
            dense
            {...rest}
        >
            {isParent && (
                <ListItemIcon>
                    <ArrowLeft01Icon data-testid='ArrowBackIcon' />
                </ListItemIcon>
            )}
            <ListItemText primary={node.name} />
            {hasChildren && <GoToChildrenButton node={node} onClick={onNavigateToChildren} />}
        </ListItemButton>
    );
};

const GoToChildrenButton: FC<{ node: FlatTreeNode; onClick: (node: FlatTreeNode) => void }> = ({ node, onClick }) => (
    <IconButton
        edge='end'
        aria-label={`go-to-${node.name}-children`}
        onClick={event => {
            event.stopPropagation();
            onClick(node);
        }}
        disabled={node.disabled}
        sx={{ p: 0.5 }}
    >
        <ArrowRight01Icon size={20} />
    </IconButton>
);
