import { FlatTreeNode, useTreeAutoComplete } from '@/components/autocomplete-wrapper/TreeAutoComplete/useTreeAutoComplete';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { DepartmentNode } from '@/domain/department/Department.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { CostCenterAssignmentListField } from '@/page/cost-center/CostCenterAssignmentListField';
import {
    DepartmentFormValues,
    DepartmentManagerFormValue,
    getDepartmentFormSchema,
} from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';
import { UserLanguage } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, DialogActions, DialogContent, FormControlLabel, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type DepartmentDialogProps = DialogWrapperProps & {
    defaultDepartment?: DepartmentNode;
    defaultParentId?: DepartmentNode['id'];
    departmentOptions: FlatTreeNode[];
    translationLanguage: UserLanguage;
    onSubmitDepartment: (department: DepartmentFormValues) => void;
};

export const DepartmentDialog: FC<DepartmentDialogProps> = props => {
    const { defaultDepartment, departmentOptions, defaultParentId, translationLanguage, onSubmitDepartment, ...restDialogProps } = props;
    const { t } = useTranslation();
    const { data: allEmployees = [] } = useGetEmployees({ statuses: [EmploymentStatus.HIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.ON_LONG_LEAVE] });

    // get the default parent option to prefill the autocomplete
    const defaultTreeNodeValue = defaultDepartment?.id ? departmentOptions.find(d => d.id === defaultDepartment.id) : undefined;
    const parentId = defaultTreeNodeValue?.parentId ?? defaultParentId;
    const defaultTreeNodeParent = departmentOptions.find(d => d.id === parentId);
    const { autocompleteProps, textFieldInputProps } = useTreeAutoComplete({
        options: departmentOptions,
        defaultValue: defaultTreeNodeParent,
    });

    const defaultValues: Partial<DepartmentFormValues> = {
        parent: defaultTreeNodeParent,
        name: defaultDepartment?.name,
        managers: defaultDepartment?.managers.map(e => mapEmployeeToDepartmentManagerOption(e)) ?? [],
        departmentCostCenters: defaultDepartment?.departmentCostCenters ?? [],
    };

    const formMethods = useForm<DepartmentFormValues>({
        resolver: yupResolver(getDepartmentFormSchema(translationLanguage)),
        defaultValues,
    });
    const { control, handleSubmit } = formMethods;

    return (
        <DialogWrapper {...restDialogProps} header={t('settings_organization.departments.add_dialog.title')}>
            <DialogContent>
                <Stack gap={2}>
                    <FieldLabel
                        name={'name'}
                        control={control}
                        fullWidth
                        autoFocus
                        label={t('settings_organization.departments.add_dialog.name_field')}
                        language={translationLanguage}
                    />
                    <Controller
                        name={'parent'}
                        control={control}
                        render={({ field: { value: _, onChange, ...restField }, fieldState: { error } }) => (
                            <FormControlLabel
                                sx={{ width: '100%' }}
                                label={t('settings_organization.departments.parent_field')}
                                control={
                                    <Autocomplete
                                        fullWidth
                                        {...autocompleteProps}
                                        onChange={(e, value, reason) => {
                                            autocompleteProps.onChange(e, value, reason);
                                            onChange(value);
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                {...restField}
                                                InputProps={{ ...params.InputProps, ...textFieldInputProps }}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        )}
                                    />
                                }
                            />
                        )}
                    />

                    <FormControlLabel
                        sx={{ width: '100%' }}
                        label={t('settings_organization.departments.add_dialog.managers_field')}
                        control={
                            <FieldSelect
                                name={'managers'}
                                control={control}
                                multiple
                                fullWidth
                                options={allEmployees.map(e => mapEmployeeToDepartmentManagerOption(e))}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={option => option.displayName}
                                getOptionKey={option => option.id}
                                autocompleteProps={{ disableCloseOnSelect: true }}
                            />
                        }
                    />
                    <FormProvider {...formMethods}>
                        <CostCenterAssignmentListField fieldName={'departmentCostCenters'} />
                    </FormProvider>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button fullWidth onClick={() => handleSubmit(onSubmitDepartment, console.error)()}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const mapEmployeeToDepartmentManagerOption = (employee: EmployeeAvatar): DepartmentManagerFormValue => ({
    id: employee.id,
    displayName: employee.displayName,
});
