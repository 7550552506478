import { LabelInputList, LabelListItemFormValues } from '@/components/label-input-list/LabelInputList';
import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Label } from '@/domain/label/Label.model';
import { getLabelPropertyName, getRealmLanguage } from '@/utils/language.util';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { isCustomFieldType, isSectionFieldType } from '@/domain/section-setting/Section.service';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { Cancel01Icon } from 'hugeicons-react';

export type SectionFieldDefinitionFormValues = Pick<SectionFieldDefinition, 'name' | 'fieldType' | 'valueType' | 'mandatory'> & {
    customList?: LabelListItemFormValues[];
};

type Props = {
    onSave: (sectionField: SectionFieldDefinitionFormValues, previousSectionField?: SectionFieldDefinition) => void;
    onClose: () => void;
    /**
     * @property defaultSectionField if undefined, it means we are creating a new section field
     */
    defaultSectionField?: SectionFieldDefinition;
};

const mapSectionFieldToSectionFieldDefinitionFormValues = (
    defaultSectionField: SectionFieldDefinition | undefined,
): SectionFieldDefinitionFormValues | undefined => {
    if (!defaultSectionField) {
        return undefined;
    }

    return {
        name: defaultSectionField.name,
        mandatory: defaultSectionField.mandatory,
        customList: defaultSectionField.customList?.items,
        fieldType: defaultSectionField.fieldType,
        valueType: defaultSectionField.valueType,
    };
};

export const SectionFieldDefinitionDialog: FC<Props> = ({ onSave, onClose, defaultSectionField }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const defaultLanguage = getRealmLanguage();
    const [translationLanguage, setTranslationLanguage] = useState(defaultLanguage);

    const defaultLabel = createDefaultLabel();

    const newDefaultSectionField = {
        name: defaultLabel,
        mandatory: true,
        fieldType: 'EMPLOYEE_CUSTOM_FIELD',
        valueType: 'STRING',
    } satisfies SectionFieldDefinitionFormValues;

    // if we are editing a section field, we fill the form with the section field data
    // otherwise we set default values to keep the form controlled
    const [sectionField, setSectionField] = useState<SectionFieldDefinitionFormValues>(
        // convert the defaultSectionField to a SectionFieldDefinitionFormValues
        mapSectionFieldToSectionFieldDefinitionFormValues(defaultSectionField) ?? newDefaultSectionField,
    );

    const isCustomField = isCustomFieldType(sectionField.fieldType);

    const handleSave = async () => {
        onSave(sectionField, defaultSectionField);
    };

    const handleSectionFieldNameChange = (name: Label) => {
        setSectionField({
            ...sectionField,
            name,
        });
    };

    const handleSectionFieldTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const type = event.target.value;
        if (isSectionFieldType(type)) {
            setSectionField({
                ...sectionField,
                valueType: type,
            });
        }
    };

    const handleSectionFieldMandatoryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSectionField({ ...sectionField, mandatory: event.target.checked });
    };

    const handleSectionFieldCustomListChange = (customList: LabelListItemFormValues[]) => {
        setSectionField({
            ...sectionField,
            customList: customList,
        });
    };

    // A form with custom fields is valid if the default language of the realm is filled
    const isFormValid = () => {
        return (
            // we check if the name is filled in the default language
            !!sectionField?.name?.[getLabelPropertyName(defaultLanguage)] && // if the type is not custom list item or custom multi list item, we don't check the custom list
            ((sectionField?.valueType !== 'CUSTOM_LIST_ITEM' && sectionField?.valueType !== 'CUSTOM_MULTI_LIST_ITEM') ||
                // if the type is custom list item or custom multi list item, we check if the custom list is filled in the default language
                ((sectionField?.valueType === 'CUSTOM_LIST_ITEM' || sectionField?.valueType === 'CUSTOM_MULTI_LIST_ITEM') &&
                    !!sectionField?.customList?.length &&
                    sectionField.customList.every(item => !!item?.label?.[getLabelPropertyName(defaultLanguage)])))
        );
    };

    const isList = sectionField.valueType === 'CUSTOM_LIST_ITEM' || sectionField.valueType === 'CUSTOM_MULTI_LIST_ITEM';

    return (
        <Dialog open={true} onClose={() => onClose()} fullScreen={fullScreen}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h1'>
                        {defaultSectionField ? t('employee_fields_page.add_field_dialog.edit_field') : t('employee_fields_page.add_field_dialog.new_field')}
                    </Typography>
                    <Stack direction='row' alignItems='center'>
                        <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                        <IconButton aria-label='close' onClick={() => onClose()}>
                            <Cancel01Icon />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    {/*todo: implement the error helper text message*/}
                    <TranslatableLabelInput
                        label={t('employee_fields_page.add_field_dialog.name')}
                        value={sectionField.name}
                        onChange={handleSectionFieldNameChange}
                        fullWidth
                        translationLanguage={translationLanguage}
                    />
                    <FormControlLabel
                        label={t('employee_fields_page.add_field_dialog.type')}
                        labelPlacement='top'
                        disabled={!!defaultSectionField} // we can't change the type of section that already exists
                        control={
                            <TextField select fullWidth onChange={handleSectionFieldTypeChange} value={defaultSectionField?.valueType} defaultValue={'STRING'}>
                                {FIELD_OPTIONS.map(fieldType => (
                                    <MenuItem key={fieldType} value={fieldType}>
                                        {t(`employee_fields_page.${fieldType}`)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                    />

                    <FormControlLabel
                        label={t('employee_fields_page.add_field_dialog.mandatory_field')}
                        labelPlacement='end'
                        control={<Switch checked={sectionField.mandatory} disabled={!isCustomField} onChange={handleSectionFieldMandatoryChange} />}
                    />

                    {isList && (
                        <LabelInputList
                            list={sectionField.customList ?? []}
                            onChange={handleSectionFieldCustomListChange}
                            translationLanguage={translationLanguage}
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={!isFormValid()} variant='contained' onClick={handleSave}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const FIELD_OPTIONS = [
    'STRING',
    'NUMBER',
    'DATE',
    'CUSTOM_LIST_ITEM',
    'CUSTOM_MULTI_LIST_ITEM',
    'SECTION_FIELD_DOCUMENT',
    'COUNTRY',
    'PHONE_NUMBER',
    'IBAN_NUMBER',
];
