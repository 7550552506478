import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Label } from '@/domain/label/Label.model';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { ObjectiveCategory } from '@/domain/objective/Objective.model';
import { getRealmLanguage } from '@/utils/language.util';

type AddCategoryDialogProps = {
    open: boolean;
    handleSave: (data: CategoryForm) => void;
    closeDialog: () => void;
    activeCategoryData: ObjectiveCategory | undefined;
    mode: 'edit' | 'create';
};

export type CategoryForm = {
    name: Label;
};

export const AddCategoryDialog: FC<AddCategoryDialogProps> = ({ open, handleSave, closeDialog, activeCategoryData, mode }) => {
    const { t } = useTranslation();

    const defaultLanguage = getRealmLanguage();
    const [translationLanguage, setTranslationLanguage] = useState(defaultLanguage);

    const schema = yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
    });
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: activeCategoryData?.name ?? createDefaultLabel(),
        },
    });

    const onSave = (data: CategoryForm) => {
        handleSave(data);
    };

    const onClose = () => {
        closeDialog();
    };

    const header = (
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
            <Typography variant={'body1bold'}>
                {mode === 'create' ? t('reviews_settings_page.category_dialog.new_category') : t('reviews_settings_page.category_dialog.edit_category')}
            </Typography>
            <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
        </Stack>
    );

    return (
        <DialogWrapper onClose={onClose} open={open} header={header}>
            <DialogContent>
                <Stack>
                    <FieldLabel
                        control={control}
                        name='name'
                        fullWidth
                        language={translationLanguage}
                        label={t('reviews_settings_page.category_dialog.category_name')}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained' color='primary'>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
