import { Button, FormControlLabel, FormHelperText, Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { EmployeesSelectionDialog } from '@/components/employee-selection-dialog/EmployeesSelectionDialog';
import { RouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Employee } from '@/domain/employee/Employee.model';
import { filterEmployeesByIds } from '@/domain/employee/Employee.service';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { getLabelTranslation, getLocalizedErrorMessage, getRealmLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { AddTeamIcon } from 'hugeicons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { TranslatableRichTextEditor } from '@/components/translatable-rich-text-editor/TranslatableRichTextEditor';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { createSurvey } from '@/domain/survey/Survey.service';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetSurveyTemplates } from '@/hooks/survey-template/SurveyTemplate.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { getSurveySetupFormSchema, SurveySetupFormSchemaType } from '@/page/survey/survey-setup/SurveySetupForm.schema';
import { setTime } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

export const SurveySetupFormPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const defaultLanguage = getRealmLanguage();
    const [appliesDialogOpen, setAppliesDialogOpen] = useState(false);
    const [translationLanguage, setTranslationLanguage] = useState(defaultLanguage);

    const { data: employees, isLoading: isEmployeesLoading, isError: isEmployeesError, error: employeesError } = useGetEmployees();
    const {
        data: surveyTemplates = [],
        isLoading: isSurveyTemplatesLoading,
        isError: isSurveyTemplatesError,
        error: surveyTemplatesError,
    } = useGetSurveyTemplates();

    const isLoading = isEmployeesLoading || isSurveyTemplatesLoading;
    const isError = isEmployeesError || isSurveyTemplatesError;
    const error = employeesError || surveyTemplatesError;

    const getDefaultValue = (): Partial<SurveySetupFormSchemaType> => {
        return {
            recipientIds: [],
            explanation: createDefaultLabel(),
            sendNotificationNow: false,
        };
    };

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        watch,
        setValue,
        control,
    } = useForm<SurveySetupFormSchemaType>({
        resolver: yupResolver(getSurveySetupFormSchema(translationLanguage)),
        defaultValues: getDefaultValue(),
    });

    const goToSurveysList = () => {
        navigate('/manage-surveys/surveys');
    };

    const onSave = async (data: SurveySetupFormSchemaType) => {
        if (!data.surveyTemplateId) {
            return;
        }

        const startDateTime = !data.sendNotificationNow && data.startDate && data.startTime ? setTime(data.startDate, data.startTime) : undefined;
        const endDateTime = data.endDate && data.endTime ? setTime(data.endDate, data.endTime) : undefined;

        const mutation = {
            surveyTemplateId: data.surveyTemplateId,
            explanation: data.explanation,
            recipientIds: data.recipientIds,
            startDate: startDateTime,
            endDate: endDateTime,
        };
        try {
            await createSurvey(mutation);
            goToSurveysList();
            showSnackbar(t('survey_templates_page.cycles.cycle_created'), 'success');
        } catch {
            showSnackbar(t('survey_templates_page.survey_create_error'), 'error');
        }
    };

    const footerActions: FooterActionsProps['actions'] = [
        {
            name: 'save',
            onClick: handleSubmit(onSave, console.error),
            children: t('survey_templates_page.cycles.create_cycle'),
            variant: 'contained',
        },
    ];

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <ContentContainer>
                <RouteLeavingGuard when={!isSubmitting} />
                <Stack direction='column' component={Paper} elevation={1} p={3}>
                    <Stack direction='column' spacing={2}>
                        <Typography variant='h1'>{t('survey_templates_page.survey')}</Typography>

                        <FormControlLabel
                            label={t('survey_templates_page.survey_template')}
                            control={
                                <FieldSelect
                                    name='surveyTemplateId'
                                    control={control}
                                    loading={isLoading}
                                    fullWidth
                                    options={surveyTemplates.flatMap(template => template.id)}
                                    getOptionLabel={option =>
                                        getLabelTranslation(surveyTemplates.find(template => template.id === option)?.name, translationLanguage)
                                    }
                                    autocompleteProps={{
                                        sx: { width: '320px' },
                                    }}
                                />
                            }
                        />

                        <Stack gap={1}>
                            <Typography variant='body1bold'>{t('survey_templates_page.send_survey_to')}</Typography>
                            <Stack gap={1} alignItems={'flex-start'} direction={'row'}>
                                <Stack>
                                    <Button
                                        variant='contained'
                                        endIcon={<AddTeamIcon />}
                                        color='primary'
                                        onClick={() => setAppliesDialogOpen(true)}
                                        sx={{ width: 'fit-content' }}
                                    >
                                        {t('permissions_setting_page.select_members')}
                                    </Button>
                                    <FormHelperText error={!!errors?.recipientIds?.message} sx={{ pl: 2, pt: 0.25 }}>
                                        {errors?.recipientIds?.message}
                                    </FormHelperText>
                                </Stack>

                                <StackedAvatars employeeAvatars={filterEmployeesByIds(watch('recipientIds'), employees ?? [])} />
                            </Stack>
                        </Stack>
                        <Stack gap={2}>
                            <Stack sx={{ width: '600px' }}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant={'body1'}>{t('survey_templates_page.cycles.explanation_message')}</Typography>
                                    <TranslationLanguageSelector handleLanguageChange={setTranslationLanguage} translationLanguage={translationLanguage} />
                                </Stack>

                                <Controller
                                    name={'explanation'}
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <TranslatableRichTextEditor
                                                minHeight={'120px'}
                                                onUpdate={field.onChange}
                                                value={field.value}
                                                translationLanguage={translationLanguage}
                                                name={'explanation'}
                                                errorMessage={getLocalizedErrorMessage(fieldState.error, translationLanguage)}
                                            />
                                        );
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Typography variant='body1bold'>{t('survey_templates_page.schedule')}</Typography>

                        <FormControlLabel
                            label={t('survey_templates_page.cycles.send_now')}
                            labelPlacement='end'
                            control={
                                <FieldSwitch
                                    name='sendNotificationNow'
                                    control={control}
                                    onChange={() => {
                                        // if we don't reset the values, the validation will fail if startTime or endTime is not a valid date
                                        setValue('startTime', undefined);
                                        setValue('startDate', undefined);
                                    }}
                                />
                            }
                        />

                        {!watch('sendNotificationNow') && (
                            <Stack direction={'row'} gap={2} alignItems={'flex-start'}>
                                <FormControlLabel
                                    sx={{ width: '320px' }}
                                    label={t('survey_templates_page.cycles.send_on')}
                                    control={<FieldLocalDate name='startDate' control={control} />}
                                />

                                <FormControlLabel label={t('general.time')} control={<FieldTime name={'startTime'} control={control} />} />
                            </Stack>
                        )}

                        <Stack direction={'row'} gap={2} alignItems={'flex-start'}>
                            <Stack>
                                <FormControlLabel
                                    sx={{ width: '320px' }}
                                    label={t('survey_templates_page.cycles.end_on')}
                                    control={<FieldLocalDate name='endDate' control={control} />}
                                />
                            </Stack>

                            <FormControlLabel label={t('general.time')} control={<FieldTime name={'endTime'} control={control} />} />
                        </Stack>
                    </Stack>
                </Stack>

                {appliesDialogOpen && (
                    <Controller
                        name='recipientIds'
                        control={control}
                        render={({ field }) => (
                            <EmployeesSelectionDialog
                                assignedEmployees={filterEmployeesByIds(field.value, employees ?? [])}
                                allEmployees={employees ?? []}
                                open={appliesDialogOpen}
                                onClose={() => setAppliesDialogOpen(false)}
                                onSave={(assigned: Employee[]) => {
                                    const assignedIds = assigned.map((employee: Employee) => employee.id);
                                    field.onChange(assignedIds);
                                    setAppliesDialogOpen(false);
                                }}
                            />
                        )}
                    />
                )}
            </ContentContainer>
            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </StateHandler>
    );
};
