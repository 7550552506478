import { Employee } from '@/domain/employee/Employee.model';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldText } from '@/components/form/field-text/FieldText';
import { ReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Add01Icon, Delete02Icon } from 'hugeicons-react';

type FormProps = {
    onSubmitForm: (data: ReviewsFormValues) => void;
    templates: ReviewTemplate[];
    employees: Employee[];
    defaultValues: Partial<ReviewFormValues>;
};
export const ReviewsForm: FC<FormProps> = ({ onSubmitForm, templates, employees, defaultValues }) => {
    const { t } = useTranslation();

    const defaultReview: Partial<ReviewFormValues> = {
        reviewName: '',
        reviewTemplate: defaultValues?.reviewTemplate ?? undefined,
        reviewNotificationDate: defaultValues?.reviewNotificationDate,
        reviewEndDate: defaultValues?.reviewEndDate,
        reviewManager: defaultValues?.reviewManager ?? undefined,
    };

    const form = useForm<ReviewsFormValues>({
        resolver: yupResolver(getReviewsFormSchema()),
        defaultValues: {
            organizeMeeting: true,
            reviews: [defaultReview],
        },
    });

    const { control, handleSubmit, setValue, watch, getFieldState } = form;

    const templateOptions = templates?.map(t => ({ label: getLabelTranslation(t.name), value: t.id }));
    const employeeOptions = employees.map(employee => ({
        label: employee.displayName,
        value: employee.id,
    }));

    const {
        fields: reviews,
        append: addReview,
        remove: removeReview,
    } = useFieldArray<ReviewsFormValues>({
        control,
        name: 'reviews',
    });

    const organizeMeeting = watch('organizeMeeting');

    const handleReviewTemplateChange = (index: number, value: number) => {
        if (value && !getFieldState(`reviews.${index}.reviewName`).isDirty) {
            const currentTemplate = templates.find(t => t.id === value);
            setValue(`reviews.${index}.reviewName`, getLabelTranslation(currentTemplate?.name) ?? '', { shouldValidate: true });
        }
    };

    return (
        <SectionContainer title={t('onboarding_form.onboarding_reviews')}>
            <form id='review-form' onSubmit={handleSubmit(onSubmitForm, console.error)}>
                <FormProvider {...form}>
                    <Stack gap={2}>
                        <Stack direction='row'>
                            <FormControlLabel
                                control={
                                    <FieldSwitch
                                        name='organizeMeeting'
                                        control={control}
                                        onChange={value => (value ? addReview(defaultReview as ReviewFormValues) : setValue('reviews', []))}
                                    />
                                }
                                label={t('onboarding_form.organize_end_of_trial_meeting')}
                                labelPlacement='end'
                            />
                        </Stack>

                        {organizeMeeting && (
                            <>
                                {reviews.map((review, index) => (
                                    <Stack key={review.id} gap={1}>
                                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                            <Typography variant='body1bold'>{t('onboarding_form.meeting')}</Typography>
                                            {reviews.length > 1 && (
                                                <IconButton size='small' onClick={() => removeReview(index)}>
                                                    <Delete02Icon />
                                                </IconButton>
                                            )}
                                        </Stack>
                                        <Divider />
                                        <SectionFieldContainer title={t('onboarding_form.review_template')} formValueName='reviewTemplate'>
                                            <FieldSelect
                                                name={`reviews.${index}.reviewTemplate`}
                                                control={control}
                                                onChange={selectedOption => {
                                                    if (selectedOption?.value) {
                                                        handleReviewTemplateChange(index, selectedOption?.value);
                                                    }
                                                }}
                                                fullWidth
                                                options={templateOptions}
                                                getOptionLabel={option => option?.label}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                            />
                                        </SectionFieldContainer>
                                        <SectionFieldContainer title={t('onboarding_form.review_name')} formValueName='reviewName'>
                                            <FieldText
                                                name={`reviews.${index}.reviewName`}
                                                control={control}
                                                fullWidth
                                                placeholder={t('onboarding_form.review_name_placeholder')}
                                            />
                                        </SectionFieldContainer>
                                        <SectionFieldContainer title={t('onboarding_form.trigger_one')} formValueName='reviewNotificationDate'>
                                            <FieldLocalDate name={`reviews.${index}.reviewNotificationDate`} control={control} />
                                        </SectionFieldContainer>
                                        <SectionFieldContainer title={t('onboarding_form.end_date')} formValueName='reviewEndDate'>
                                            <FieldLocalDate name={`reviews.${index}.reviewEndDate`} control={control} />
                                        </SectionFieldContainer>
                                        <SectionFieldContainer title={t('onboarding_form.manager')} formValueName={`reviews.${index}.reviewManager`}>
                                            <FieldSelect
                                                name={`reviews.${index}.reviewManager`}
                                                control={control}
                                                fullWidth
                                                options={employeeOptions}
                                                getOptionLabel={option => option?.label ?? ''}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                            />
                                        </SectionFieldContainer>
                                    </Stack>
                                ))}
                                <Stack direction='row'>
                                    <Button variant='text' onClick={() => addReview(defaultReview as ReviewFormValues)} startIcon={<Add01Icon />}>
                                        {t('onboarding_form.add_other_meetings')}
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </FormProvider>
            </form>
        </SectionContainer>
    );
};

const getReviewsFormSchema = () => {
    return yup.object().shape({
        organizeMeeting: yup.boolean().default(true),
        reviews: yup
            .array()
            .required()
            .of(
                yup.object().shape({
                    reviewName: yup.string().required(),
                    // Set default undefined to check required validation before nested object validation
                    reviewTemplate: yup.object().default(undefined).required().shape({
                        value: yup.number().required(),
                        label: yup.string().required(),
                    }),
                    reviewNotificationDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
                    reviewEndDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
                    reviewManager: yup
                        .object()
                        // Set default undefined to check required validation before nested object validation
                        .default(undefined)
                        .required()
                        .shape({
                            value: yup.number().required(),
                            label: yup.string().required(),
                        }),
                }),
            )
            .when('organizeMeeting', {
                is: true,
                then: schema => schema.required().min(1),
            }),
    });
};

export type ReviewsFormValues = yup.InferType<ReturnType<typeof getReviewsFormSchema>>;
type ReviewFormValues = ReviewsFormValues['reviews'][0];
